<template>
  <div class="notificationcontent">
    <div class="">
      <div class="d-flex pt-5">
        <div class="col-md-6 d-flex align-items-center justify-content-start">
          <div class="title">Feedback</div>
        </div>
      </div>

      <div class="" id="">
        <div v-for="(feedbacks, date) in groupedFeedback" :key="date">
          <div
            style="
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              margin-left: 51px;
              margin-top: 19px;
              color: #ffffff;
            "
          >
            {{ date }}
          </div>
          <ul class="no-dot">
            <li v-for="feedback in feedbacks" :key="feedback.id">
              <div
                class="align-items-center rounded-3 mb-5 bg-white mt-3"
                :key="feedback.id"
                style="
                  margin-right: 42px;
                  margin-left: 42px;
                  border-radius: 33px;
                "
              >
                <div class="text-dark">
                  <div class="d-flex">
                    <div class="col-xl-10 m-7">
                      <div>
                        <div class="msgtitle mb-3">
                          {{ feedback.user.email }}
                        </div>

                        <div class="mb-5">
                          <p class="msg" style="white-space: pre-line">
                            {{ feedback.feedback_comments }}
                          </p>
                          <div class="msgtime">
                            {{ formatDate(feedback.created_at) }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-xl-2"
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <div class="">
                        <!-- Button or icon can go here -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- </b-sidebar> -->
  </div>
</template>

<script>
import { GETFEEDBACK } from "../../core/services/store/auth.module";

export default {
  name: "Feedback",

  data() {
    return {
      tabIndex: 0,
      feedbacks: [],
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    groupedFeedback() {
      return this.feedbacks
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort by created_at in descending order
        .reduce((acc, feedback) => {
          const date = feedback.created_at.split(" ")[0]; // Get the date part
          if (!acc[date]) {
            acc[date] = []; // Initialize the array for this date if it doesn't exist
          }
          acc[date].push(feedback); // Push the feedback into the correct date array
          return acc;
        }, {});
    },
  },

  methods: {
    /**
     * Set current active on click
     * @param event
     */
    closeSidebar() {
      this.isOpen = false;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
        weekday: "short",
        hour: "numeric",
        minute: "numeric",
        timeZone: "Europe/London",
      }).format(date);
      // month:'2-digit',day:'2-digit', year:'numeric',hour:'2-digit',minute:'2-digit'
      // {dateStyle: 'short'}
    },
    formatwise(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
        weekday: "short",
        timeZone: "Europe/London",
      }).format(date);
      // month:'2-digit',day:'2-digit', year:'numeric',hour:'2-digit',minute:'2-digit'
      // {dateStyle: 'short'}
    },
    getData() {
      this.$store
        .dispatch(GETFEEDBACK)
        .then((data) => {
          this.feedbacks = data;
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
/* .offcanvas { width: 360px; height: 100%; position: fixed; top: 0; overflow: inherit; -webkit-transition: all 0.6s; transition: all 0.6s; z-index: 10000; } */

.offcanvas-size-xxl {
  /* width: 600px !important; */
  height: 10% !important;
}

.offcanvas-size-xxl {
  --bs-offcanvas-width: min(95vw, 90vw) !important;
}

.offcanvas-size-md {
  /* add Responsivenes to default offcanvas */
  --bs-offcanvas-width: min(95vw, 400px) !important;
}

.offcanvas-size-sm {
  --bs-offcanvas-width: min(95vw, 250px) !important;
}

/* .b-sidebar {
    width: 100%;
    height: 700vh;
  }
  
  .b-icon.bi {
      display: inline-block;
      overflow: visible;
      vertical-align: -0.15em;
      color: white;
  }
  .b-sidebar>.b-sidebar-header .close {
    font-size: 40px;
    color: white;
  }
  
  .scroll.ps>.ps__rail-y>.ps__thumb-y {
    top: 60px;
    height: 70px;
    width: 13px;
    /* background-color: #625656eb; */
/* }  */

.view_button {
  font-size: 17px;
  border-radius: 10px;
  margin-left: 17px;
  height: 31px;
  width: 81px;
  text-align: center;
  padding-top: 2px;
}

.scroll.ps > .ps__rail-x {
  top: 60px;
  height: 70px;
  width: 13px;
  /* background-color: #625656eb; */
}

/* .b-sidebar-backdrop { */

/* width: 94vw;
      margin-left: 101px;s
      height: 165vh;
      opacity: 70%;
      color: #101012;
  } */
.notificationcontent {
  /* background-color: #101012; */
  /* background-color: #58585A !important; */
  /* opacity: 0.7 !important; */
  min-height: 100%;
  min-width: 100%;
}

/* .b-sidebar>.b-sidebar-body {
    padding-left: 128px;
  
  } */

.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  color: #3d415b;
}

.msgtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  color: #3d415b;
}

.msg {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;

  text-align: justify;
  color: #3d415b;
}

.content {
  padding: 0;
}

.msgtime {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  align-items: center;
  display: flex;
  color: #3d415b;
}

.no-dot {
  list-style: none;
}
.clearbutton {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3d415b;
  border: none;
  background: none;
}

.msgtime {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  align-items: center;
  display: flex;
  color: #3d415b;
}
</style>
